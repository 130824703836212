<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="600"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-select
          title="选择酒店"
          v-model:value="storeId"
          :options="list"
        />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {} from '@ant-design/icons-vue'

import form from '@/mixins/form'
import { UserClass } from '@/apis/user'

const api = new UserClass()
export default defineComponent({
  components: {
  },
  mixins: [form],
  emits: ['ok'],
  setup () {
    const loading = ref(false)
    const visible = ref(false)
    const title = ref('')
    const user = ref(null)
    const list = ref([])
    const storeId = ref()

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      user,
      list,
      storeId,
      onClose
    }
  },

  methods: {
    open (formData) {
      this.user = formData

      this.getAllStores()
      this.visible = true
    },

    async getAllStores () {
      const list =
        this.$store.state.data.ALL_STORES.length > 0
          ? this.$store.state.data.ALL_STORES
          : await this.$store.dispatch('GetAllStores')

      const vlist = []
      list.map(e => {
        vlist.push({
          label: e.title,
          value: e.id
        })
      })

      this.list = vlist
    },

    onSubmitFun () {
      this.loading = true
      api.bind(this.user.id, this.storeId).then(() => {
        this.$message.success('绑定成功')
        this.$emit('ok')
        this.onClose()
      })
    }
  }
})
</script>

<style lang="less" scoped>

</style>
